<template>
    <v-content class="loginContent">
        <v-container class="fill-height"
                     fluid>
            <v-row align="center"
                   justify="center">
                <v-col cols="12"
                       sm="8"
                       md="4">
                    <v-card class="elevation-12">
                        <v-toolbar color="#213B87"
                                   dark
                                   flat>
                            <v-toolbar-title>
                                <router-link to="/">
                                    <img src="../assets/images/WEB-BOT-EROVET.png" width="175" alt="logoerovet">
                                </router-link>
                            </v-toolbar-title>
                            <v-spacer/>
                        </v-toolbar>
                        <v-card-text>
                          <v-alert
                              border="right"
                              colored-border
                              type="error"
                              v-if="ko"
                              elevation="2"
                          >
                            There was an error, please try again later
                          </v-alert>
                          <v-alert
                              border="right"
                              colored-border
                              type="success"
                              v-if="ok"
                              elevation="2">
                            A link has been sent to your email to reset your password
                          </v-alert>
                            <v-form @keyup.enter.native="submit">
                                <v-text-field
                                        label="E-Mail"
                                        name="login"
                                        v-model="email"
                                        prepend-icon="person"
                                        type="text"/>

                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn color="#213B87" class="loginBT" :loading="loading" v-on:click="submit">Send me a E-Mail</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
    export default {
        name: "forgot",
        data() {
            return {
                loading: false,
                emptyError: false,
                apiError: false,
                notFoundError: false,
                email: '',
              ok:false,
              ko:false
            }
        },
        mounted() {
            this.checkSession()
        },
        methods: {
            submit() {
                this.loading = true;
                this.ok = false;
                this.ko = false;
                if (this.email === '') {
                    this.emptyError = true;
                    this.loading = false;
                } else {
                    this.emptyError = false;
                    let url = this.base_url + '/home/forgot';
                    let formData = new FormData();
                    formData.set('email', this.email);
                    this.axios.post(url, formData).then(() => {
                      this.loading = false;
                      this.ok = true
                    }).catch(()=>{
                      this.loading = false;
                      this.ko = true;
                    })
                }
            }
        }
    };
</script>
<style>
    .loginBT {
        font-size: 12pt !important;
        margin: 0 0.5em 0 0.5em;
        padding: 0.6em !important;
    }

    .emptyAlert {
        background: #9D130B !important;
    }
</style>
